import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import PortableText from "../components/PortableText"
import { Link } from "gatsby"
import Aos from "aos"
import amosovaword from "../images/amosova.png"
import olgaword from "../images/olga.png"
import { BiLeftArrow } from "react-icons/bi"

const IndexPage = ({ data }) => {
  const { _rawBody, title } = data.sanityConcertProgram

  useEffect(() => {
    Aos.init({ duration: 1000, once: true })
  }, [])

  return (
    <Layout>
      <Seo title={title} />

      <div className="bg-red-700">
        <div className="container mx-auto h-20 bg-red-700">
          <div className="grid grid-cols-2">
            <div className="col-span-2 bg-red-800 flex items-center justify-center h-20">
              <div className="text-6xl text-white font-sans relative">
                <img
                  src={amosovaword}
                  alt="Amosova"
                  width="350px"
                  data-aos="fade-left"
                  className="nodraggable"
                />
                <img
                  src={olgaword}
                  alt="Olga"
                  width="300px"
                  className="absolute z-20 opacity-5 nodraggable px-5"
                  data-aos="fade-right"
                  style={{ top: "54px", left: "34px" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden">
          <div className="bg-gray-700">
            <div className="container mx-auto bg-white">
              <div className="grid grid-cols-2">
                <div className="col-span-2 bg-yellow-100 z-10"></div>
                <div className="col-span-2 order-first relative bg-white z-10">
                  <div className="bg-gray-200 р-20 flex items-center justify-center relative">
                    <div className="h-20"></div>
                  </div>
                  <div className="bg-gray-300 р-20 flex items-center justify-center relative">
                    <Link to="/" className="pl-5 font-bold">
                      <div className="h-10 font-sans text-xl flex flex-wrap items-center justify-center">
                        <BiLeftArrow />
                        <BiLeftArrow />
                        <BiLeftArrow />
                        Вернуться назад
                      </div>
                    </Link>
                  </div>
                  <div
                    className="p-10 md:p-20"
                    data-aos="fade-down"
                    data-aos-delay="400"
                  >
                    <div className="text-gray-800 text-4xl font-sans">
                      {title}
                    </div>
                    <hr />
                    <PortableText textData={_rawBody} data-aos="zoom-in-down" />
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute bottom-10 z-0 bg-red-700 w-full h-20" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    sanityConcertProgram {
      _rawBody(resolveReferences: { maxDepth: 10 })
      _rawHeaderImage(resolveReferences: { maxDepth: 10 })
      title
    }
  }
`
export default IndexPage
